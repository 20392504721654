<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <new-address :ubigeo="ubigeo" :departments="departments" :provinces="provinces" :districts="districts"
                 :clients="[]"
                 :client="document.quote.client"
                 @add="addAddressSidebar" :isSidebarActive="showAddressSidebar"
                 @closeSidebar="showAddressSidebar= false"/>

    <search-quotes :quotes="quotes" @add="addQuoteSidebar" :isSidebarActive="showQuotesSidebar"
                   @closeSidebar="showQuotesSidebar= false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva guía de remisión</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!--Quotes-->
        <div>
          <vs-button @click="showQuotesSidebar= true" :disabled="progress" class="w-full" color="danger"
                     type="border">
            Buscar cotización
          </vs-button>
        </div>
        <!-- Client and Quote -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Cotización*" v-model="document.quote.code" disabled/>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Cliente*" v-model="document.quote.client.businessName" disabled/>
            </div>
          </div>
        </div>

        <!--Address and order--->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Dirección de entrega*</label>
              <v-select :options="addresses"
                        label="alias"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.address" class="mb-4 md:mb-0" name="address" id="address"
                        v-validate="'required'">
                <template v-if="document.quote.client" #list-header>
                  <li @click="showAddressSidebar= true"
                      class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                    />
                    <span class="align-middle ml-25">
                  Nueva dirección
                </span>
                  </li>
                </template>
              </v-select>
              <span class="text-danger text-sm"
                    v-show="errors.has('address')">{{ errors.first('address') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Orden de compra" v-model="document.purchaseOrder" v-validate="'max:255'"
                        name="purchaseOrder" id="purchaseOrder"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('purchaseOrder')">{{ errors.first('purchaseOrder') }}</span>
            </div>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Vehicle and transport-->
        <div class="vx-row mt-5">
          <!--Vehicle-->
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Vehículo*</label>
            <v-select label="driverName" :options="vehicles" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.vehicle" class="mb-4 md:mb-0" name="vehicle"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('vehicle')">{{ errors.first('vehicle') }}</span>
          </div>
          <!--End-->
          <!--Type of transport-->
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Tipo de transporte*</label>
            <v-select :options="typeOfTransport" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.typeOfTransport" class="mb-4 md:mb-0" name="typeOfTransport"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('typeOfTransport')">{{ errors.first('typeOfTransport') }}</span>
          </div>
          <!--End-->
        </div>
        <!--End-->

        <!--Carrier and document--->
        <div v-if="document.typeOfTransport?.id === '01'" class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Denominación transportista*" v-model="document.carrier"
                      v-validate="'max:255'"
                      name="carrier" id="carrier"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Documento transportista*" v-model="document.documentOfCarrier"
                      v-validate="'max:255'"
                      name="documentOfCarrier" id="documentOfCarrier"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('documentOfCarrier')">{{ errors.first('documentOfCarrier') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input @blur="tr.quantity > tr.max ? tr.quantity = tr.max: '' " style="width: 60px" min="1"
                            :max="tr.max" type="number"
                            v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="mr-2 cursor-pointer" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>

                <feather-icon class="cursor-pointer" icon="HashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="openSerialDialog(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="preSubmit" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
    <!--Product series-->
    <vs-prompt
        @cancel="serials = ''"
        @accept="assignSerials"
        @close="serials = ''"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series"
        :active.sync="showSerialDialog">
      <div class="con-exemple-prompt">
        Ingresa los números de serie
        <vs-textarea rows="5" label="Series" v-model="serials" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import getQuote from '@/mixins/quotes'
import vSelect from 'vue-select'
import NewAddress from '@/views/clients/addresses/New'
import SearchQuotes from '@/views/purchases/SearchQuotes'

import { db, FieldValue } from '@/firebase/firebaseConfig'
import axios from 'axios'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

const _ = require('lodash')

export default {
  name: 'NewGuide',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    quotes: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    NewAddress,
    SearchQuotes
  },
  mixins: [trimString, getQuote],
  data () {
    return {
      document: {
        address: null,
        quote: {
          client: {}
        },
        typeOfTransport: null
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      typeOfTransport: [
        { id: '01', label: 'TRANSPORTE PÚBLICO' },
        { id: '02', label: 'TRANSPORTE PRIVADO' }
      ],
      progress: false,
      addresses: [],
      // Sidebars
      showNewProviderSidebar: false,
      showAddressSidebar: false,
      showNewProductSidebar: false,
      showQuotesSidebar: false,
      showSerialDialog: false,
      // Emd
      addedProducts: [],
      selectedProduct: null,
      serials: '',
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          address: null,
          quote: {
            client: {}
          },
          typeOfTransport: null
        }
        this.document.vehicle = this.vehicles[0]
        this.addedProducts = []
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Pre submit
     */
    preSubmit () {
      if (this.document.purchaseOrder && this.document.purchaseOrder.length !== 0) {
        this.submitData()
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmación',
          text: `¿Deseas generar la guía sin orden de compra?`,
          accept: this.submitData,
          cancel: '',
          parameters: [],
          acceptText: 'Generar',
          cancelText: 'Cancelar'
        })
      }
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.document.quote.id) {
            this.progress = true
            // Get sequence
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequenceForGuides', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            const sequence = response.data.sequence
            let obj = {
              ...this.document,
              state: true,
              status: 'hold',
              products: [
                ...this.addedProducts
              ],
              user: this.document.quote.user || null,
              code: 'TTT1-' + sequence,
              type: 'regular',
              sequence,
              serie: 'TTT1'
            }
            // Filter Products
            obj.filterProducts = []
            obj.products.forEach((p) => {
              obj.filterProducts.push(p.numberPart)
            })
            // End
            // Save in firestore
            const doc = await db.collection('guides').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            // Add purchases products to quote
            this.document.quote.products.forEach((qp) => {
              const product = this.addedProducts.find((pp) => pp.id === qp.id && pp.index === qp.index)
              if (product) {
                qp.guide += product.quantity
              }
            })
            // Update Quote status and products
            await db.collection('quotes').doc(this.document.quote.id).update({
              status: 'inProcess',
              products: this.document.quote.products
            })
            obj.id = doc.id
            obj.createdAt = new Date()

            // Send NubeFact and update
            const responseNubefact = await this.sendNubefact(sequence)
            if (responseNubefact !== -1) {
              await db.collection('guides').doc(doc.id).update({
                nubefact: {
                  ...responseNubefact.nubefact
                },
                status: 'send',
                accepted: false
              })
              // Open PDF in browser
              window.open(responseNubefact.nubefact.enlace_del_pdf, 'blank')
              obj.status = 'send'
            }
            obj.nubefact = {
              ...responseNubefact.nubefact
            }
            obj.accepted = false
            // End

            // Verify guide
            setTimeout(async () => {
              await this.verifyGuide(obj)
            }, 10000)
            // End

            this.$emit('closeSidebar')
            this.$emit('add', obj)
            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía de remisión creada correctamente.'
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Filter address by provider
     */
    async filterAddressByClient () {
      try {
        if (this.document.quote.client.id) {
          const clientDoc = await db.collection('clients').doc(this.document.quote.client.id).get()
          this.addresses = []
          this.addresses.push({
            alias: 'Principal',
            address: clientDoc.data().address,
            department: clientDoc.data().department,
            province: clientDoc.data().province,
            district: clientDoc.data().district,
            ubigeo: clientDoc.data().ubigeo,
            label: clientDoc.data().address + ' - ' + clientDoc.data().department + ' - ' + clientDoc.data().province + ' - ' + clientDoc.data().district
          })
          this.progress = true
          this.list = []
          const querySnap = await db.collection('clients').doc(this.document.quote.client.id)
              .collection('addresses')
              .where('state', '==', true)
              .orderBy('createdAt', 'desc').get()
          querySnap.forEach((doc) => {
            let obj = {
              id: doc.id,
              alias: doc.data().alias,
              address: doc.data().address,
              department: doc.data().department,
              province: doc.data().province,
              district: doc.data().district,
              ubigeo: doc.data().ubigeo
            }
            this.addresses.push(obj)
          })
          this.document.address = this.addresses[0]
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Address sidebar
     * @param o
     */
    addAddressSidebar (o) {
      let obj = {
        id: o.id,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district
      }
      obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
      this.addresses.unshift(obj)
    },
    /**
     * Search quote sidebar
     */
    async addQuoteSidebar (o) {
      try {
        this.progress = true
        this.showQuotesSidebar = false
        this.addedProducts = []
        const quote = await this.getQuote(o.id)
        this.document.quote = _.cloneDeep(quote)
        this.document.client = _.cloneDeep(this.document.quote.client)
        await this.filterAddressByClient()
        this.document.quote.products.forEach((p, index) => {
          p.index = p.index ? p.index : index
          const obj = {
            id: p.id,
            sku: p.sku,
            numberPart: p.numberPart,
            price: p.price,
            purchasePrice: 0,
            quantity: p.purchaseOrder - p.guide,
            max: p.purchaseOrder - p.guide,
            description: p.description,
            serials: [],
            index: p.index
          }
          if (obj.quantity > 0) {
            this.addedProducts.push(obj)
          }
        })
        if (this.addedProducts.length === 0) {
          this.$vs.notify({
            color: 'warning',
            title: 'Atención',
            text: 'Cotización no tiene productos pendientes de entrega.'
          })
          // Reset
          this.document = {
            address: null,
            quote: {
              client: {}
            },
            typeOfTransport: {}
          }
          this.addresses = []
          await this.$validator.reset()
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Delete product
     */
    deleteProduct (product) {
      if (this.addedProducts.length !== 1) {
        const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
        this.addedProducts.splice(indexProduct, 1)
      }
    },
    /**
     * Seng guide tu nubefact
     */
    async sendNubefact (sequence) {
      try {
        const items = []
        this.addedProducts.forEach((p) => {
          const product = _.cloneDeep(p)
          if (product.serials) {
            product.description += `\n NS: ${product.serials.join(', ')}`
          }
          const obj = {
            'unidad_de_medida': 'NIU',
            'codigo': product.numberPart.toUpperCase(),
            'descripcion': product.description,
            'cantidad': product.quantity
          }
          items.push(obj)
        })
        // Split emails
        // Get client
        const clientDoc =await db.collection('clients').doc(this.document.quote.client.id).get()
        const emails = clientDoc.data().email !== '_' ? clientDoc.data().email.split(',') : []
        // End
        let document = {
          'operacion': 'generar_guia',
          'tipo_de_comprobante': 7,
          'serie': 'TTT1',
          'numero': sequence,
          'cliente_tipo_de_documento': this.document.quote.client.typeDocument === 'RUC' ? 6 : 1,
          'cliente_numero_de_documento': this.document.quote.client.document + '',
          'cliente_denominacion': clientDoc.data().businessName,
          'cliente_direccion': clientDoc.data().address,
          // 'cliente_email': emails.length >= 1 ? emails[0].trim() : '',
          'cliente_email': 'ppuerta@anglidata.com',
          'cliente_email_1': emails.length >= 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length >= 3 ? emails[2].trim() : '',
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'observaciones': this.document.purchaseOrder ? ('OC. Cliente: ' + this.document.purchaseOrder) : '',
          'motivo_de_traslado': '01',
          'peso_bruto_total': '1',
          'numero_de_bultos': '0',
          'tipo_de_transporte': this.document.typeOfTransport.id,
          'fecha_de_inicio_de_traslado': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),

          'transportista_documento_tipo': 6, // 7 if RUc
          'transportista_documento_numero': this.document.typeOfTransport.id === '02' ? '20341031517' : this.document.documentOfCarrier,
          'transportista_denominacion': this.document.typeOfTransport.id === '02' ? 'AngliData Express EIRL' : this.document.carrier,
          'transportista_placa_numero': 'C6K-758',

          'punto_de_partida_ubigeo': '150141',
          'punto_de_partida_direccion': '"CAL. PEREZ ARANIBAR NRO. 134 INT. 303',
          'punto_de_llegada_ubigeo': this.document.address.ubigeo +"",
          'punto_de_llegada_direccion': this.document.address.address,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'codigo_unico': '',
          'formato_de_pdf': ''
        }

        if (this.document.typeOfTransport.id === '02') {
          document = {
            ...document,
            //
            'conductor_documento_tipo': 1,
            'conductor_documento_numero': this.document.vehicle.driverDocument,
            'conductor_denominacion': this.document.vehicle.driverName,

            'conductor_numero_licencia': 'Q07263813',
            'conductor_nombre': 'Pedro',
            'conductor_apellidos': 'Puerta',
            //
          }
        }

        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendReferenceGuides', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        return response.data
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
          time: 7000
        })
        return -1
      }
    },
    /**
     * Open Serial dialog
     */
    openSerialDialog (tr) {
      this.selectedProduct = tr
      this.showSerialDialog = true
      this.serials = this.selectedProduct.serials.join('\n')
    },
    /**
     * Assign serials
     */
    assignSerials () {
      const product = this.addedProducts.find((p) => p.id === this.selectedProduct.id)
      product.serials = []
      this.serials.split(/\r?\n|\r|\n/g).forEach((s) => {
        product.serials.push(s)
      })
      this.serials = ''
      this.showSerialDialog = false
    },
    /**
     * Verify guide
     * @param tr
     */
    async verifyGuide (tr) {
      try {
        if (!tr.accepted) {
          const response = await axios.post(' https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-verifyGuide', {
            document: {
              'operacion': 'consultar_guia',
              'tipo_de_comprobante': 7,
              'serie': tr.serie,
              'numero': tr.sequence
            }
          }, {
            headers: {
              Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
            }
          })

          /// Update in firestore
          await db.collection('guides').doc(tr.id).update({
            nubefact: {
              ...response.data.nubefact
            },
            accepted: response.data.nubefact.aceptada_por_sunat
          })
          // End

          if (response.data.nubefact.aceptada_por_sunat) {
            // Open PDF in browser
            window.open(response.data.nubefact.enlace_del_pdf, 'blank')
            // End
            // Update row
            tr.nubefact = {
              ...response.data.nubefact
            }
            tr.accepted = true
            // End

            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía aceptada por Sunat',
              time: 5000
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Guía de remisión',
              text: 'Guía no aceptada por Sunat',
              time: 5000
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
