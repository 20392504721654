<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <new-client @add="addClientSidebar" :isSidebarActive="showNewClientSidebar"
                @closeSidebar="showNewClientSidebar= false"/>

    <new-address :ubigeo="ubigeo" :departments="departments" :provinces="provinces" :districts="districts"
                 :clients="[]"
                 :client="document.client"
                 @add="addAddressSidebar" :isSidebarActive="showAddressSidebar"
                 @closeSidebar="showAddressSidebar= false"/>

    <new-product @add="addProductSidebar" :isSidebarActive="showNewProductSidebar"
                 @closeSidebar="showNewProductSidebar= false"/>

    <search :products="products" @add="addProductSidebar" :isSidebarActive="showSearchProductsSidebar"
            @closeSidebar="showSearchProductsSidebar= false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva guía sin cotización</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Client -->
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Cliente*</label>
            <v-select @input="getClientData" :options="clients"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.client" class="mb-4 md:mb-0" name="client" id="client"
                      v-validate="'required'">
              <template #list-header>
                <li @click="showNewClientSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nuevo cliente
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('client')">{{ errors.first('client') }}</span>
          </div>

          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Motivo*</label>
            <v-select :options="motives"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.motive" class="mb-4 md:mb-0" name="motive" id="motive"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('motive')">{{ errors.first('motive') }}</span>
          </div>
        </div>
        <!--Address and Purchase Order--->
        <div class="vx-row mt-5">
          <!-- Address -->
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Dirección de entrega*</label>
            <v-select :options="addresses"
                      label="alias"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.address" class="mb-4 md:mb-0" name="address" id="address"
                      v-validate="'required'">
              <template v-if="document.client" #list-header>
                <li @click="showAddressSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nueva dirección
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>

          <!-- Purchase order -->
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Orden de compra" v-model="document.purchaseOrder" v-validate="'max:255'"
                        name="purchaseOrder" id="purchaseOrder"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('purchaseOrder')">{{ errors.first('purchaseOrder') }}</span>
            </div>
          </div>
        </div>

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Vehicle and transport-->
        <div class="vx-row mt-5">
          <!--Vehicle-->
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Vehículo*</label>
            <v-select label="driverName" :options="vehicles" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.vehicle" class="mb-4 md:mb-0" name="vehicle"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('vehicle')">{{ errors.first('vehicle') }}</span>
          </div>
          <!--End-->
          <!--Type of transport-->
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Tipo de transporte*</label>
            <v-select :options="typeOfTransport" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.typeOfTransport" class="mb-4 md:mb-0" name="typeOfTransport"
                      v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('typeOfTransport')">{{ errors.first('typeOfTransport') }}</span>
          </div>
          <!--End-->
        </div>
        <!--End-->

        <!--Carrier and document--->
        <div v-if="document.typeOfTransport?.id === '01'" class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Denominación transportista*" v-model="document.carrier"
                      v-validate="'max:255'"
                      name="carrier" id="carrier"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Documento transportista*" v-model="document.documentOfCarrier"
                      v-validate="'max:255'"
                      name="documentOfCarrier" id="documentOfCarrier"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('documentOfCarrier')">{{ errors.first('documentOfCarrier') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Products-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-button @click="showSearchProductsSidebar= true" :disabled="progress" class="w-full" color="danger"
                       type="filled">
              Buscar producto
            </vs-button>
          </div>
          <div class="vs-col w-1/2">
            <vs-button @click="showNewProductSidebar = true" :disabled="progress" class="w-full" color="danger"
                       type="border">
              Crear producto
            </vs-button>
          </div>
        </div>

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">
          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input style="width: 60px" min="1" type="number" v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="mr-2 cursor-pointer" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>

                <feather-icon class="cursor-pointer" icon="HashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="openSerialDialog(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="preSubmit" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
    <!--Product series-->
    <vs-prompt
        @cancel="serials = ''"
        @accept="assignSerials"
        @close="serials = ''"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series"
        :active.sync="showSerialDialog">
      <div class="con-exemple-prompt">
        Ingresa los números de serie
        <vs-textarea rows="5" label="Series" v-model="serials" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'
import NewClient from '@/views/clients/New'
import NewAddress from '@/views/clients/addresses/New'
import NewProduct from '@/views/products/New'
import Search from '@/views/guides/SearchProducts'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'NewGuideWQ',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    NewClient,
    NewAddress,
    NewProduct,
    Search
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        address: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      addresses: [],
      // Sidebars
      showNewClientSidebar: false,
      showAddressSidebar: false,
      showNewProductSidebar: false,
      showSearchProductsSidebar: false,
      showSerialDialog: false,
      // End
      selectedProduct: null,
      serials: '',
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo,
      addedProducts: [],
      selected: null,
      total: 0,
      motives: [
        {
          id: '01',
          label: 'VENTA'
        }, {
          id: '14',
          label: 'VENTA SUJETA A CONFIRMACION DEL COMPRADOR'
        },
        {
          id: '02',
          label: 'COMPRA'
        },
        {
          id: '04',
          label: 'TRASLADO EMISOR ITINERANTE CP'
        },
        {
          id: '19',
          label: 'TRASLADO A ZONA PRIMARIA'
        },
        {
          id: '13',
          label: 'OTROS'
        }
      ],
      typeOfTransport: [
        { id: '01', label: 'TRANSPORTE PÚBLICO' },
        { id: '02', label: 'TRANSPORTE PRIVADO' }
      ]
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.addedProducts = []
        this.document = {
          address: {}
        }
        this.document.vehicle = this.vehicles[0]
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Pre submit
     */
    preSubmit () {
      if (this.document.purchaseOrder && this.document.purchaseOrder.length !== 0) {
        this.submitData()
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmación',
          text: `¿Deseas generar la guía sin orden de compra?`,
          accept: this.submitData,
          cancel: '',
          parameters: [],
          acceptText: 'Generar',
          cancelText: 'Cancelar'
        })
      }
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.document.client.id) {
            this.progress = true
            // Get sequence
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequenceForGuides', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            const sequence = response.data.sequence
            let obj = {
              ...this.document,
              state: true,
              status: 'hold',
              products: [
                ...this.addedProducts
              ],
              user: {
                displayName: auth.currentUser.displayName,
                uid: auth.currentUser.uid
              },
              code: 'TTT1-' + sequence,
              type: 'wq',
              sequence,
              serie: 'TTT1'
            }
            // Save in firestore
            const doc = await db.collection('guides').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            obj.id = doc.id
            obj.createdAt = new Date()

            // Send NubeFact and update
            const responseNubefact = await this.sendNubefact(sequence)
            if (responseNubefact !== -1) {
              await db.collection('guides').doc(doc.id).update({
                nubefact: {
                  ...responseNubefact.nubefact
                },
                status: 'send',
                accepted: false
              })
              // Open PDF in browser
              window.open(responseNubefact.nubefact.enlace_del_pdf, 'blank')
              obj.status = 'send'
            }
            obj.nubefact = {
              ...responseNubefact.nubefact
            }
            obj.accepted = false
            // End
            this.$emit('closeSidebar')
            this.$emit('add', obj)
            this.$vs.notify({
              color: 'success',
              title: 'Guía de remisión',
              text: 'Guía de remisión creada correctamente.'
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Seng guide tu nubefact
     */
    async sendNubefact (sequence) {
      try {
        const items = []
        this.addedProducts.forEach((p) => {
          const product = _.cloneDeep(p)
          if (product.serials) {
            product.description += `\n NS: ${product.serials.join(', ')}`
          }
          const obj = {
            'unidad_de_medida': 'NIU',
            'codigo': product.numberPart.toUpperCase(),
            'descripcion': product.description,
            'cantidad': product.quantity
          }
          items.push(obj)
        })
        // Split emails
        const emails = this.document.client.email !== '_' ? this.document.client.email.split(',') : []
        // End
        let document = {
          'operacion': 'generar_guia',
          'tipo_de_comprobante': 7,
          'serie': 'TTT1',
          'numero': sequence,
          'cliente_tipo_de_documento': this.document.client.typeDocument === 'RUC' ? 6 : 1,
          'cliente_numero_de_documento': this.document.client.document,
          'cliente_denominacion': this.document.client.businessName,
          'cliente_direccion': this.document.client.address,
          // 'cliente_email': emails.length >= 1 ? emails[0].trim() : '',
          'cliente_email': 'ppuerta@anglidata.com',
          'cliente_email_1': emails.length >= 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length >= 3 ? emails[2].trim() : '',
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'observaciones': this.document.purchaseOrder ? ('OC. Cliente: ' + this.document.purchaseOrder) : '',
          'motivo_de_traslado': this.document.motive.id,
          'peso_bruto_total': '1',
          'numero_de_bultos': '0',
          'tipo_de_transporte': '02',
          'fecha_de_inicio_de_traslado': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),

          'transportista_documento_tipo': 6, // 7 if RUc
          'transportista_documento_numero': this.document.typeOfTransport.id === '02' ? '20341031517' : this.document.documentOfCarrier,
          'transportista_denominacion': this.document.typeOfTransport.id === '02' ? 'AngliData Express EIRL' : this.document.carrier,
          'transportista_placa_numero': 'C6K-758',

          'conductor_numero_licencia': 'Q07263813',
          'conductor_nombre': 'Pedro',
          'conductor_apellidos': 'Puerta',

          'punto_de_partida_ubigeo': '150141',
          'punto_de_partida_direccion': '"CAL. PEREZ ARANIBAR NRO. 134 INT. 303',
          'punto_de_llegada_ubigeo': this.document.address.ubigeo,
          'punto_de_llegada_direccion': this.document.address.address,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'codigo_unico': '',
          'formato_de_pdf': ''
        }


        if (this.document.typeOfTransport.id === '02') {
          document = {
            ...document,
            //
            'conductor_documento_tipo': 1,
            'conductor_documento_numero': this.document.vehicle.driverDocument,
            'conductor_denominacion': this.document.vehicle.driverName,

            'conductor_numero_licencia': 'Q07263813',
            'conductor_nombre': 'Pedro',
            'conductor_apellidos': 'Puerta',
            //
          }
        }

        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendReferenceGuides', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        return response.data
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
          time: 7000
        })
        return -1
      }
    },
    /**
     * Filter address by client
     */
    async filterAddressByClient () {
      try {
        if (this.document.client.id) {
          this.addresses = []
          this.addresses.push({
            alias: 'Principal',
            address: this.document.client.address,
            department: this.document.client.department,
            province: this.document.client.province,
            district: this.document.client.district,
            ubigeo: this.document.client.ubigeo,
            label: this.document.client.address + ' - ' + this.document.client.department + ' - ' + this.document.client.province + ' - ' + this.document.client.district
          })
          this.initProgress = true
          this.list = []
          const querySnap = await db.collection('clients').doc(this.document.client.id)
              .collection('addresses')
              .where('state', '==', true)
              .orderBy('createdAt', 'desc').get()
          querySnap.forEach((doc) => {
            let obj = {
              id: doc.id,
              alias: doc.data().alias,
              address: doc.data().address,
              department: doc.data().department,
              province: doc.data().province,
              district: doc.data().district,
              ubigeo: doc.data().ubigeo
            }
            this.addresses.push(obj)
          })
          this.document.address = this.addresses[0]
          this.initProgress = false
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Get client data
     * @returns {Promise<void>}
     */
    getClientData () {
      try {
        this.document.address = null
        this.filterAddressByClient()
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Client Sidebar
     */
    addClientSidebar (o) {
      this.clients.unshift({
        id: o.id,
        document: o.document,
        typeDocument: o.typeDocument,
        businessName: o.businessName,
        label: o.businessName + ' - ' + o.document,
        email: o.email,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district,
        ubigeo: o.ubigeo
      })
    },
    /**
     * Address sidebar
     * @param o
     */
    addAddressSidebar (o) {
      let obj = {
        id: o.id,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district
      }
      obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
      this.addresses.unshift(obj)
    },
    /**
     * Search product sidebar
     */
    addProductSidebar (o) {
      let findProduct = this.addedProducts.find((p) => p.id === o.id)
      if (!findProduct) {
        let obj = {
          id: o.id,
          description: o.description,
          sku: o.id.substr(0, 6),
          numberPart: o.numberPart,
          deliveryTerm: 7,
          warranty: 12,
          price: 0,
          quantity: 1,
          guide: 0,
          invoice: 0,
          purchaseOrder: 0,
          serials: []
        }
        this.addedProducts.push(obj)
      } else {
        findProduct.quantity++
      }
      this.showSearchProductsSidebar = false
    },
    /**
     * Delete product
     */
    deleteProduct (product) {
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * Open Serial dialog
     */
    openSerialDialog (tr) {
      this.selectedProduct = tr
      this.showSerialDialog = true
      this.serials = this.selectedProduct.serials.join('\n')
    },
    /**
     * Assign serials
     */
    assignSerials () {
      const product = this.addedProducts.find((p) => p.id === this.selectedProduct.id)
      product.serials = []
      this.serials.split(/\r?\n|\r|\n/g).forEach((s) => {
        product.serials.push(s)
      })
      this.serials = ''
      this.showSerialDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
