<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de productos</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <vs-table class="mt-5" :data="document.products">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Series</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 50%" :data="tr.description">
                <vs-textarea disabled rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p>{{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <p>{{ tr.quantity }}</p>
              </vs-td>

              <vs-td>
                <feather-icon class="cursor-pointer" icon="HashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="openSerialDialog(tr)"/>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </component>
    <!--Footer-->
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button @click="submitData" class="mr-6" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
    <!--Product series-->
    <vs-prompt
        @cancel="serials = ''"
        @accept="assignSerials"
        @close="serials = ''"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series"
        :active.sync="showSerialDialog">
      <div class="con-exemple-prompt">
        Ingresa los números de serie
        <vs-textarea rows="5" label="Series" v-model="serials" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { db, auth, FieldValue } from '@/firebase/firebaseConfig'

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {
        products: []
      },
      progress: false,
      showSerialDialog: false,
      selectedProduct: null,
      serials: ''
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      this.document = _.cloneDeep(this.data)
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Open Serial dialog
     */
    openSerialDialog (tr) {
      this.selectedProduct = tr
      this.showSerialDialog = true
      this.serials = this.selectedProduct.serials.join('\n')
    },
    /**
     * Assign serials
     */
    assignSerials () {
      const product = this.document.products.find((p) => p.id === this.selectedProduct.id)
      product.serials = []
      this.serials.split(/\r?\n|\r|\n/g).forEach((s) => {
        product.serials.push(s)
      })
      this.serials = ''
      this.showSerialDialog = false
    },
    /**
     * Update guide
     */
    async submitData () {
      try {
        this.progress = true
        await db.collection('guides').doc(this.document.id).update({
          products: this.document.products,
          updatedAt: FieldValue.serverTimestamp(),
          userUpdated: {
            displayName: auth.currentUser.displayName,
            uid: auth.currentUser.uid
          }
        })
        this.$emit('update', this.document)
        this.$emit('closeSidebar')
        this.$vs.notify({
          color: 'success',
          title: 'Guía de remisión',
          text: 'Guía de remisión actualizada correctamente.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
